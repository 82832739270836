<template>
  <div class="text-center">
    <img :src="getImage" />
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: "large",
    },
  },
  computed: {
    getTemplateFolder() {
      return this.$root.template_name;
    },
    getImage() {
      switch (this.size) {
        case "small":
          return `/wp-content/themes/${this.getTemplateFolder}/assets/images/spinner-small.gif`;
        default:
        case "large":
          return `/wp-content/themes/${this.getTemplateFolder}/assets/images/spinner.gif`;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
div {
  width: 100%;
  display: block;
}
img {
  max-width: 100%;
}
</style>