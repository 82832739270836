<template>
  <div>
    <InputFormContainer
      meta-key="life-outline"
      @save="saveChanges"
      :height="500"
    />
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
export default {
  components: {
    InputFormContainer,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
};
</script>

<style scoped>
div .input-form {
  height: 500px;
}
</style>