<template>
  <div class="wrapper">
    <button
      class="btn btn-success btn-sm btn-save"
      v-if="show_save"
      @click="saveChanges"
    >
      <i class="fa fa-save"></i> Save
    </button>
    <div class="input-wrap">
      <Spinner size="small" v-if="loading" />
      <template v-else>
        <template v-if="type === `input`">
          <input
            type="text"
            :id="metaKey"
            v-model="inputContent"
            :placeholder="placeholder"
            @keypress="triggerChange"
          />
        </template>
        <div class="checkbox" v-else-if="type === `checkbox`">
          <input
            type="checkbox"
            v-model="inputContent"
            @change="triggerClickCheckbox"
            :id="metaKey"
          />
          {{ placeholder }}
        </div>
        <template v-else>
          <textarea
            v-model="inputContent"
            :placeholder="placeholder"
            @keypress="triggerChange"
            :style="{ height: inputHeight }"
            :id="metaKey"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Spinner from "./Spinner.vue";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      content: "",
      show_save: false,
      timer: {
        type: Object,
        default: 0,
      },
      loading: true,
    };
  },
  props: {
    placeholder: String,
    metaKey: String,
    type: String,
    height: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    inputContent: {
      get() {
        switch (this.type) {
          case "checkbox":
            return this.content === `false` || this.content === ``
              ? false
              : true;
          default:
            return this.content;
        }
      },
      set(value) {
        this.content = value;
      },
    },
    inputHeight() {
      return `${this.height}px`;
    },
  },
  methods: {
    triggerChange() {
      clearTimeout(this.timer);
      this.show_save = true;
      this.timer = setTimeout(() => {
        this.saveChanges();
      }, 5000);
    },
    saveChanges() {
      clearTimeout(this.timer);
      this.show_save = false;
      this.$emit("save", { metaKey: this.metaKey, content: this.content });
    },
    triggerClickCheckbox() {
      clearTimeout(this.timer);
      this.show_save = false;
      this.$emit("save", { metaKey: this.metaKey, content: this.content });
    },
  },
  created() {
    this.$store.dispatch("getUserMeta", [
      this.metaKey,
      ({ meta_value }) => {
        this.content = meta_value;
        this.loading = false;
      },
    ]);
  },
};
</script>
<style scoped>
.wrapper {
  position: relative;
  margin: 0;
  padding: 0;
}
.input-wrap {
  border: none;
}
.input-wrap input {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
}
.input-wrap input[type="checkbox"] {
  height: 1rem;
  width: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-wrap > textarea {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
}

.input-wrap > input:focus,
.input-wrap > textarea:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.btn-save {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 5px;
}
.checkbox {
  line-height: 20px;
}
.checkbox > input {
  margin-right: 1rem;
}
</style>